<template>
  <div class="p-6">
    <DialogTitle :label="title" align="center" />
    <div v-if="filterable" class="flex justify-center mt-8 mb-4">
        <md-button class="text-uppercase outline-gray-400 rounded bg-victoria text-white">All</md-button>
        <md-button class="text-uppercase outline-gray-400 rounded">Pending</md-button>
    </div>
    <md-table>
        <md-table-row>
          <md-table-head 
            v-for="(key, index) in kyes" :key="'h'+index"
            class="text-uppercase"
            >
            {{ key.replace( /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5' ) }}
          </md-table-head>
          <md-table-head v-if="action">
            Action
          </md-table-head>
        </md-table-row>

        <md-table-row v-for="item in content" :key="'b'+item.id">
          <md-table-cell v-for="(key, index) in kyes" :key="index">{{ item[key] }}</md-table-cell>
            <md-table-cell v-if="action">
              <md-icon class="bg-link-water rounded-full p-1 text-victoria text-base">visibility</md-icon>
            </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="flex justify-center mt-8 mb-4">
        <md-button class="text-uppercase outline-gray-400 rounded" @click="dialog(false)">Exit</md-button>
        <md-button class="text-uppercase rounded bg-victoria text-white">Go To Course Expiration Page</md-button>
      </div>
  </div>
</template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

<script>
import { DialogTitle } from "@/components";
import { mapMutations } from 'vuex';
export default {
  components: {
    DialogTitle,
  },
  props: {
      title: {
          type: String,
          default: () => 'Title goes to here'
      },
      content: {
        type: Array,
        required: true
      },
      options: {
        type: Object
      }
  },
  computed: {
    kyes() {
      if(this.content.length > 0) {
       return Object.keys(this.content[0])
      }
      return []
    },
    action() {
      return this.options.action
    },
    filterable() {
      return this.options.filterable
    }
  },
  methods: mapMutations({
    dialog: "setShowDialog",
  }),
};
</script>

<style></style>
