const branchEnrollments = [];
for (let i = 1; i < 5; i++) {
    branchEnrollments.push({
        id: i,
        studentName: 'Cristiano Ronaldo',
        emailAddress: 'ronaldo@fotball.con',
        branch: 'Dhanmondi',
        course: 'Exp. Listening',
        courseFee: '$2500',
        paymentDate: '20 Aug, 2019',
        status: 'Collected'
    });
}

const discounts = [];
for (let i = 1; i < 5; i++) {
    discounts.push({
        id: i,
        discountCodeName: 'Discount For Father\'s Day',
        branch: 'Dhanmondi',
        expiryDate: '20 Oct, 2020',
        discount: '25%',
        usages: '5',
        createdBy: 'Kazi Shahin'
    });
}

const collections = [];
for (let i = 1; i < 5; i++) {
    collections.push({
        id: i,
        studentName: 'Cristiano Ronaldo',
        branch: 'Dhanmondi',
        courseName: 'Exp. Listening',
        courseFee: '$2500',
        due: '$500',
        nextInstallment: '20 Aug, 2019',
        status: 'Collected'
    });
}


const courseExpirationStudents = [];
for (let i = 1; i < 5; i++) {
    courseExpirationStudents.push({
        id: i,
        studentId: 'STID12'+i,
        branch: 'Dhanmondi',
        courseName: 'Exp. Listening',
        courseDuration: '4 Months',
        admittedAt: '20 Aug, 2019',
        expirationDate: '20 Aug, 2020',
        timeRemaining: '2 D 30 Hr 25 Min'
    });
}


const studentExamDateList = [];
for (let i = 1; i < 5; i++) {
    studentExamDateList.push({
        id: i,
        date: '12 May, 2019',
        day: 'Sun',
        time: '6:00am',
        examName: 'Expert on Listening',
        courseName: 'Speaking',
        examCenter: 'Dhanmondi Head Exam center',
        examGroup: 'Group A',
        createdBy: 'Kazi Shahin'
    });
}


const communicationMessageArchive = [];
for (let i = 1; i < 5; i++) {
    communicationMessageArchive.push({
        id: i,
        messageTitle: 'Welcome To APICAL',
        for: 'Facebook / Twitter',
        shortDescription: 'This message will be used fo welcoming a student with details'
    });
}

const branchLogisticRequest = [];
for (let i = 1; i < 5; i++) {
    branchLogisticRequest.push({
        id: i,
        requestFor: 'Offset Paper',
        quantity: '1 Box',
        createdAt: '9 May, 2020',
        branch: 'Dhanmondi',
        status: 'Pending'
    });
}



export default {
    branchEnrollments,
    discounts,
    collections,
    courseExpirationStudents,
    studentExamDateList,
    communicationMessageArchive,
    branchLogisticRequest
}