<template>
  <div class="alertify-task-component p-3 rounded-lg" :class="classObject">
    <div class="flex justify-between flex-wrap">
      <div class="">
        <div class="flex text-uppercase">
          <div class="alertify-icon rounded">
            <i class="flaticon-file icon-resize"></i>
          </div>
          <div class="pl-2 notice">
            <h2 class="title pb-2">{{ title }}</h2>
            <h3 class="text-white">
              <small>Tomorrow | 06:00 PM</small> {{ subTitle }}
            </h3>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <md-button @click="$emit('click')" class="ml-0 md-dense bg-white">View details</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    classObject: {
      type: String,
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
    })
};
</script>

<style lang="scss">
.icon-resize {
  font-size: 40px;
  color: #fff;
}
.alertify-task-component {
  .alertify-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .notice {
    .title {
      color: rgba(0, 0, 0, 0.3);
    }
    small {
      display: inline-block;
      border: 1px solid #fff;
      padding: 2px;
      border-radius: 4px;
    }
  }
}
</style>
