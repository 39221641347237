<template>
  <div>
    <div class="md-layout-item">
      <AlertifyTaskComponent
        @click="onShowDialog()"
        class="mb-3"
        classObject="bg-green"
        title="Up Next Event"
        sub-title="Exam on expart listening"
        />
      <AlertifyTaskComponent
        @click="onShowDialog()"
        classObject="bg-blue-chill"
        title="Up Next Collection"
        sub-title="Leonal Messi | $2500"
        />
    </div>
    <div class="md-layout-item mb-3">
      <TaskTableComponent label="Branch Enrollment">
        <template slot="header">
          <div class="p-3 flex justify-between ">
            <div class="inline-flex flex-wrap justify-xs-center">
              <!-- <SelectComponent placeholder="SELECT DATE" class="w-48 m-1" /> -->
              <SelectComponent :items="branches" placeholder="SELECT BRANCH" class="w-48 m-1" />
            </div>
            <div
              @click="onShowTaskDialog({
                label:'Branch Enrollment',
                content: branchEnrollments,
                filterable: true
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer">
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="branchEnrollments" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-label="STUDENT NAME" md-sort-by="studentName">{{ item.studentName }}</md-table-cell>
              <md-table-cell md-label="EMAIL ADDRESS" md-sort-by="emailAddress">{{ item.emailAddress }}</md-table-cell>
              <md-table-cell md-label="BRANCH" md-sort-by="branch">{{ item.branch }}</md-table-cell>
              <md-table-cell md-label="COURSE" md-sort-by="course">{{ item.course }}</md-table-cell>
              <md-table-cell md-label="COURSE FEE" md-sort-by="courseFee">{{ item.courseFee }}</md-table-cell>
              <md-table-cell md-label="PAYMENT DATE" md-sort-by="paymentDate">{{ item.paymentDate }}</md-table-cell>
              <md-table-cell md-label="STATUS" class="text-green" md-sort-by="status">{{ item.status }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Branch Enrollment',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Discount/Promotion Code">
        <template slot="header">
          <div class="p-3 flex justify-end">
            <!-- <SelectComponent placeholder="SORT BY PERCENTAGE" class="w-48 m-1" /> -->
            <div
              @click="onShowTaskDialog({
                label:'Discount/Promotion Code',
                content: discounts
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer"
            >
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="discounts" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="discountCodeName" md-label="DISCOUNT CODE NAME">{{ item.discountCodeName }}</md-table-cell>
              <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
              <md-table-cell md-sort-by="expiryDate" md-label="EXPIRY DATE">{{ item.expiryDate }}</md-table-cell>
              <md-table-cell md-sort-by="discount" md-label="DISCOUNT">{{ item.discount }}</md-table-cell>
              <md-table-cell md-sort-by="usages" md-label="USAGES">{{ item.usages }}</md-table-cell>
              <md-table-cell md-sort-by="createdBy" md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Discount/Promotion Code',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Dues List & Collection">
        <template slot="header">
          <div class="p-3 flex justify-end">
            <!-- <SelectComponent placeholder="SHORT BY DUE DATE" class="w-48 m-1" /> -->
            <div
              @click="onShowTaskDialog({
                label:'Dues List & Collection',
                content: collections
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer"
            >
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="collections" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="studentName" md-label="STUDENT NAME">{{ item.studentName }}</md-table-cell>
              <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
              <md-table-cell md-sort-by="courseName" md-label="COURSE NAME">{{ item.courseName }}</md-table-cell>
              <md-table-cell md-sort-by="courseFee" md-label="COURSE FEE">{{ item.courseFee }}</md-table-cell>
              <md-table-cell md-sort-by="due" md-label="DUE">{{ item.due }}</md-table-cell>
              <md-table-cell md-sort-by="nextIntallment" md-label="NEXT INSTALLMENT">{{ item.nextInstallment }}</md-table-cell>
              <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Dues List & Collection',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Course Expiration Student">
        <template slot="header">
          <div class="p-3 flex justify-between ">
            <div class="inline-flex flex-wrap justify-xs-center">
              <!-- <SelectComponent placeholder="SELECT DATE" class="w-48 m-1" /> -->
              <SelectComponent :items="branches" placeholder="SELECT BRANCH" class="w-48 m-1" />
            </div>
            <div
              @click="onShowTaskDialog({
                label:'Course Expiration Student',
                content: courseExpirationStudents
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer">
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="courseExpirationStudents" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="studentId" md-label="STUDENT ID">{{ item.studentId }}</md-table-cell>
              <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
              <md-table-cell md-sort-by="courseName" md-label="COURSE NAME">{{ item.courseName }}</md-table-cell>
              <md-table-cell md-sort-by="courseDuration" md-label="COURSE DURATION">{{ item.courseDuration }}</md-table-cell>
              <md-table-cell md-sort-by="admittedAt" md-label="ADMITTED AT">{{ item.admittedAt }}</md-table-cell>
              <md-table-cell md-sort-by="expirationDate" md-label="EXPIRATION DATE">{{ item.expirationDate }}</md-table-cell>
              <md-table-cell md-sort-by="timeRemaining" md-label="TIME REMAINING">{{ item.timeRemaining }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Course Expiration Student',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Student Exam Date List">
        <template slot="header">
          <div class="p-3 flex justify-between">
            <SelectComponent placeholder="SELECT DATE" class="w-48 m-1" />
            <div
              @click="onShowTaskDialog({
                label:'Student Exam Date List',
                content: studentExamDateList
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer"
            >
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="studentExamDateList" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="date" md-label="DATE">{{ item.date }}</md-table-cell>
              <md-table-cell md-sort-by="day" md-label="DAY">{{ item.day }}</md-table-cell>
              <md-table-cell md-sort-by="time" md-label="TIME">{{ item.time }}</md-table-cell>
              <md-table-cell md-sort-by="examName" md-label="EXAM NAME">{{ item.examName }}</md-table-cell>
              <md-table-cell md-sort-by="courseName" md-label="COURSE NAME">{{ item.courseName }}</md-table-cell>
              <md-table-cell md-sort-by="examCenter" md-label="EXAM CENTRE">{{ item.examCenter }}</md-table-cell>
              <md-table-cell md-sort-by="examGroup" md-label="EXAM GROUP">{{ item.examGroup }}</md-table-cell>
              <md-table-cell md-sort-by="createdBy" md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Student Exam Date List',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Communication message archive">
        <template slot="header">
          <div class="p-3 flex justify-between flex-wrap justify-xs-center">
            <SearchBox placeholder="search" class="w-56 p-2 m-1" />
            <div class="flex m-1">
              <div
                @click="onShowTaskDialog({
                  label:'Communication message archive',
                  content: communicationMessageArchive
                })"
                class="ml-2 w-10 h-10 p-2 rounded outline-gray-400 pointer">
                <md-icon>open_in_full</md-icon>
              </div>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="communicationMessageArchive" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="messageIitle" md-label="MESSAGE TITLE">{{ item.messageTitle }}</md-table-cell>
              <md-table-cell md-sort-by="for" md-label="FOR">{{ item.for }}</md-table-cell>
              <md-table-cell md-sort-by="shortDescription" md-label="SHORT DESCRIPTION">{{ item.shortDescription }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Communication message archive',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>

    <div class="md-layout-item">
      <TaskTableComponent label="Branch logistic request">
        <template slot="header">
          <div class="p-3 flex justify-end">
            <!-- <SelectComponent placeholder="SELECT DATE" class="w-48 m-1" /> -->
            <div
              @click="onShowTaskDialog({
                label:'Branch logistic request',
                content: branchLogisticRequest
              })"
              class="p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer"
            >
              <md-icon>open_in_full</md-icon>
            </div>
          </div>
        </template>
        <template slot="table">
          <Table :items="branchLogisticRequest" hover-action>
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-sort-by="requestFor" md-label="REQUEST FOR">{{ item.requestFor }}</md-table-cell>
              <md-table-cell md-sort-by="quantity" md-label="QUANTITY">{{ item.quantity }}</md-table-cell>
              <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
              <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
              <md-table-cell md-sort-by="status" md-label="STATUS" class="text-danger">{{ item.status }}</md-table-cell>
              <span class="action">
                <md-icon @click.native="onShowDetails({
                  label: 'Branch logistic request',
                  content: item
                  })" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              </span>
            </template>
        </Table>
        </template>
      </TaskTableComponent>
    </div>
    <Dialog>
      <component 
        :is="component"
        :title="dialogTitle"
        :content="dialogContent"
        :options="options"
      ></component>
    </Dialog>
  </div>
</template>

<script>
import { SelectComponent, Dialog, Table } from "@/components";
import TaskTableComponent from "@/components/molecule/TaskTableComponent";
import AlertifyTaskComponent from "@/components/molecule/AlertifyTaskComponent";
import TaskDialogContent from "@/components/molecule/TheTaskDialogComponent";
import DetailsDialog from "@/components/molecule/task/DetailsDialog";
import AlertifyDetails from "@/components/molecule/AlertifyDetails";
import SearchBox from "@/components/atom/SearchBox";
import data from "@/data/task/data";
import cms from "@/data/cms";
import { mapMutations } from "vuex";
export default {
  name: "Teacher-Task-View",
  components: {
    Table,
    Dialog,
    SearchBox,
    DetailsDialog,
    AlertifyDetails,
    SelectComponent,
    TaskTableComponent,
    TaskDialogContent,
    AlertifyTaskComponent
  },
  data() {
    return {
      component: 'TaskDialogContent',
      branchEnrollments: data.branchEnrollments,
      discounts: data.discounts,
      collections: data.collections,
      courseExpirationStudents: data.courseExpirationStudents,
      studentExamDateList: data.studentExamDateList,
      communicationMessageArchive: data.communicationMessageArchive,
      branchLogisticRequest: data.branchLogisticRequest,
      dialogTitle: null,
      dialogContent: null,
      branches: cms.branches,
      options: {
        action: true,
        increasable: true
      }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDialog() {
      this.component = 'AlertifyDetails';
      this.dialogTitle = 'Cristiano Ronaldo';
      this.dialog(true);
    },
    onShowTaskDialog({label, content, filterable = false}) {
      this.component = 'TaskDialogContent';
      this.dialogTitle = label;
      this.dialogContent = content;
      this.options.filterable = filterable;
      this.dialog(true);
    },
    onSelect() {},
    onShowDetails({label, content}){
      this.component = 'DetailsDialog';
      this.dialogTitle = label;
      delete content.id;
      this.dialogContent = content;
      this.dialog(true);
    }
  },
};
</script>

