<template>
  <div class="p-6" style="width: 660px">
    <DialogTitle :label="title" bg-color="bg-victoria" align="center" />
    <div class="my-6">
      <p class="text-victoria text-base text-uppercase">Student ID</p>
      <h2 class="text-xl mt-1">STD124</h2>
    </div>

    <div class="my-6">
      <p class="text-victoria text-base text-uppercase">Branch</p>
      <h2 class="text-xl mt-1">Dhanmondi</h2>
    </div>
    <div class="my-6">
      <p class="text-victoria text-base text-uppercase">Course</p>
      <h2 class="text-xl mt-1">EXP. Listening</h2>
    </div>
    <div class="my-6">
      <table class="w-50p">
        <tr>
          <td class="text-victoria text-base text-uppercase">Exam Name</td>
          <td class="text-victoria text-base text-uppercase">Exam Type</td>
        </tr>
        <tr>
          <td><h2 class="text-xl mt-1 mb-3">STD124</h2></td>
          <td><h2 class="text-xl mt-1 mb-3">Mock Test</h2></td>
        </tr>

        <tr>
          <td class="text-victoria text-base text-uppercase">Exam Date</td>
          <td class="text-victoria text-base text-uppercase">Exam Time</td>
        </tr>
        <tr>
          <td><h2 class="text-xl mt-1">23 Oct, 2020</h2></td>
          <td><h2 class="text-xl mt-1">9:00pm</h2></td>
        </tr>
      </table>
    </div>
    <div class="mt-16 flex justify-center">
      <md-button @click="dialog(false)" class="outline-gray-500 rounded"
        >Back</md-button
      >
    </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
  },
  props: {
      title: {
          type: String,
          default: () => 'Title goes to here'
      }
  },
  methods: mapMutations({
    dialog: "setShowDialog",
  }),
};
</script>

<style></style>
