<template>
  <Card>
    <template slot="outer">
      <div class="py-3 inline-flex align-center">
        <md-icon>healing</md-icon>
        <h3 class="pl-2 text-uppercase">{{ label }}</h3>
      </div>
    </template>
    <CardHeader>
      <slot name="header"></slot>
    </CardHeader>
    <CardBody class="p-3">
      <md-divider></md-divider>
      <slot name="table"></slot>
    </CardBody>
  </Card>
</template>

<script>
import { Card, CardHeader, CardBody } from "@/components";
export default {
  name: "Todo-List",
  components: {
    Card,
    CardHeader,
    CardBody
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: () => "#514b99",
    },
  },
};
</script>

<style></style>
