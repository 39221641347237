var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md-layout-item"},[_c('AlertifyTaskComponent',{staticClass:"mb-3",attrs:{"classObject":"bg-green","title":"Up Next Event","sub-title":"Exam on expart listening"},on:{"click":function($event){return _vm.onShowDialog()}}}),_c('AlertifyTaskComponent',{attrs:{"classObject":"bg-blue-chill","title":"Up Next Collection","sub-title":"Leonal Messi | $2500"},on:{"click":function($event){return _vm.onShowDialog()}}})],1),_c('div',{staticClass:"md-layout-item mb-3"},[_c('TaskTableComponent',{attrs:{"label":"Branch Enrollment"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-between"},[_c('div',{staticClass:"inline-flex flex-wrap justify-xs-center"},[_c('SelectComponent',{staticClass:"w-48 m-1",attrs:{"items":_vm.branches,"placeholder":"SELECT BRANCH"}})],1),_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Branch Enrollment',
              content: _vm.branchEnrollments,
              filterable: true
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.branchEnrollments,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-label":"STUDENT NAME","md-sort-by":"studentName"}},[_vm._v(_vm._s(item.studentName))]),_c('md-table-cell',{attrs:{"md-label":"EMAIL ADDRESS","md-sort-by":"emailAddress"}},[_vm._v(_vm._s(item.emailAddress))]),_c('md-table-cell',{attrs:{"md-label":"BRANCH","md-sort-by":"branch"}},[_vm._v(_vm._s(item.branch))]),_c('md-table-cell',{attrs:{"md-label":"COURSE","md-sort-by":"course"}},[_vm._v(_vm._s(item.course))]),_c('md-table-cell',{attrs:{"md-label":"COURSE FEE","md-sort-by":"courseFee"}},[_vm._v(_vm._s(item.courseFee))]),_c('md-table-cell',{attrs:{"md-label":"PAYMENT DATE","md-sort-by":"paymentDate"}},[_vm._v(_vm._s(item.paymentDate))]),_c('md-table-cell',{staticClass:"text-green",attrs:{"md-label":"STATUS","md-sort-by":"status"}},[_vm._v(_vm._s(item.status))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Branch Enrollment',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Discount/Promotion Code"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-end"},[_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Discount/Promotion Code',
              content: _vm.discounts
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.discounts,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"discountCodeName","md-label":"DISCOUNT CODE NAME"}},[_vm._v(_vm._s(item.discountCodeName))]),_c('md-table-cell',{attrs:{"md-sort-by":"branch","md-label":"BRANCH"}},[_vm._v(_vm._s(item.branch))]),_c('md-table-cell',{attrs:{"md-sort-by":"expiryDate","md-label":"EXPIRY DATE"}},[_vm._v(_vm._s(item.expiryDate))]),_c('md-table-cell',{attrs:{"md-sort-by":"discount","md-label":"DISCOUNT"}},[_vm._v(_vm._s(item.discount))]),_c('md-table-cell',{attrs:{"md-sort-by":"usages","md-label":"USAGES"}},[_vm._v(_vm._s(item.usages))]),_c('md-table-cell',{attrs:{"md-sort-by":"createdBy","md-label":"CREATED BY"}},[_vm._v(_vm._s(item.createdBy))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Discount/Promotion Code',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Dues List & Collection"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-end"},[_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Dues List & Collection',
              content: _vm.collections
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.collections,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"studentName","md-label":"STUDENT NAME"}},[_vm._v(_vm._s(item.studentName))]),_c('md-table-cell',{attrs:{"md-sort-by":"branch","md-label":"BRANCH"}},[_vm._v(_vm._s(item.branch))]),_c('md-table-cell',{attrs:{"md-sort-by":"courseName","md-label":"COURSE NAME"}},[_vm._v(_vm._s(item.courseName))]),_c('md-table-cell',{attrs:{"md-sort-by":"courseFee","md-label":"COURSE FEE"}},[_vm._v(_vm._s(item.courseFee))]),_c('md-table-cell',{attrs:{"md-sort-by":"due","md-label":"DUE"}},[_vm._v(_vm._s(item.due))]),_c('md-table-cell',{attrs:{"md-sort-by":"nextIntallment","md-label":"NEXT INSTALLMENT"}},[_vm._v(_vm._s(item.nextInstallment))]),_c('md-table-cell',{staticClass:"text-green",attrs:{"md-sort-by":"status","md-label":"STATUS"}},[_vm._v(_vm._s(item.status))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Dues List & Collection',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Course Expiration Student"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-between"},[_c('div',{staticClass:"inline-flex flex-wrap justify-xs-center"},[_c('SelectComponent',{staticClass:"w-48 m-1",attrs:{"items":_vm.branches,"placeholder":"SELECT BRANCH"}})],1),_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Course Expiration Student',
              content: _vm.courseExpirationStudents
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.courseExpirationStudents,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"studentId","md-label":"STUDENT ID"}},[_vm._v(_vm._s(item.studentId))]),_c('md-table-cell',{attrs:{"md-sort-by":"branch","md-label":"BRANCH"}},[_vm._v(_vm._s(item.branch))]),_c('md-table-cell',{attrs:{"md-sort-by":"courseName","md-label":"COURSE NAME"}},[_vm._v(_vm._s(item.courseName))]),_c('md-table-cell',{attrs:{"md-sort-by":"courseDuration","md-label":"COURSE DURATION"}},[_vm._v(_vm._s(item.courseDuration))]),_c('md-table-cell',{attrs:{"md-sort-by":"admittedAt","md-label":"ADMITTED AT"}},[_vm._v(_vm._s(item.admittedAt))]),_c('md-table-cell',{attrs:{"md-sort-by":"expirationDate","md-label":"EXPIRATION DATE"}},[_vm._v(_vm._s(item.expirationDate))]),_c('md-table-cell',{attrs:{"md-sort-by":"timeRemaining","md-label":"TIME REMAINING"}},[_vm._v(_vm._s(item.timeRemaining))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Course Expiration Student',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Student Exam Date List"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-between"},[_c('SelectComponent',{staticClass:"w-48 m-1",attrs:{"placeholder":"SELECT DATE"}}),_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Student Exam Date List',
              content: _vm.studentExamDateList
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)],1)]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.studentExamDateList,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"date","md-label":"DATE"}},[_vm._v(_vm._s(item.date))]),_c('md-table-cell',{attrs:{"md-sort-by":"day","md-label":"DAY"}},[_vm._v(_vm._s(item.day))]),_c('md-table-cell',{attrs:{"md-sort-by":"time","md-label":"TIME"}},[_vm._v(_vm._s(item.time))]),_c('md-table-cell',{attrs:{"md-sort-by":"examName","md-label":"EXAM NAME"}},[_vm._v(_vm._s(item.examName))]),_c('md-table-cell',{attrs:{"md-sort-by":"courseName","md-label":"COURSE NAME"}},[_vm._v(_vm._s(item.courseName))]),_c('md-table-cell',{attrs:{"md-sort-by":"examCenter","md-label":"EXAM CENTRE"}},[_vm._v(_vm._s(item.examCenter))]),_c('md-table-cell',{attrs:{"md-sort-by":"examGroup","md-label":"EXAM GROUP"}},[_vm._v(_vm._s(item.examGroup))]),_c('md-table-cell',{attrs:{"md-sort-by":"createdBy","md-label":"CREATED BY"}},[_vm._v(_vm._s(item.createdBy))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Student Exam Date List',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Communication message archive"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-between flex-wrap justify-xs-center"},[_c('SearchBox',{staticClass:"w-56 p-2 m-1",attrs:{"placeholder":"search"}}),_c('div',{staticClass:"flex m-1"},[_c('div',{staticClass:"ml-2 w-10 h-10 p-2 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
                label:'Communication message archive',
                content: _vm.communicationMessageArchive
              })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])],1)]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.communicationMessageArchive,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"messageIitle","md-label":"MESSAGE TITLE"}},[_vm._v(_vm._s(item.messageTitle))]),_c('md-table-cell',{attrs:{"md-sort-by":"for","md-label":"FOR"}},[_vm._v(_vm._s(item.for))]),_c('md-table-cell',{attrs:{"md-sort-by":"shortDescription","md-label":"SHORT DESCRIPTION"}},[_vm._v(_vm._s(item.shortDescription))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Communication message archive',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('div',{staticClass:"md-layout-item"},[_c('TaskTableComponent',{attrs:{"label":"Branch logistic request"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-3 flex justify-end"},[_c('div',{staticClass:"p-2 m-1 w-10 h-10 rounded outline-gray-400 pointer",on:{"click":function($event){return _vm.onShowTaskDialog({
              label:'Branch logistic request',
              content: _vm.branchLogisticRequest
            })}}},[_c('md-icon',[_vm._v("open_in_full")])],1)])]),_c('template',{slot:"table"},[_c('Table',{attrs:{"items":_vm.branchLogisticRequest,"hover-action":""},scopedSlots:_vm._u([{key:"row",fn:function({ item }){return [_c('md-table-cell',{attrs:{"md-sort-by":"id","md-label":"SL","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-sort-by":"requestFor","md-label":"REQUEST FOR"}},[_vm._v(_vm._s(item.requestFor))]),_c('md-table-cell',{attrs:{"md-sort-by":"quantity","md-label":"QUANTITY"}},[_vm._v(_vm._s(item.quantity))]),_c('md-table-cell',{attrs:{"md-sort-by":"createdAt","md-label":"CREATED AT"}},[_vm._v(_vm._s(item.createdAt))]),_c('md-table-cell',{attrs:{"md-sort-by":"branch","md-label":"BRANCH"}},[_vm._v(_vm._s(item.branch))]),_c('md-table-cell',{staticClass:"text-danger",attrs:{"md-sort-by":"status","md-label":"STATUS"}},[_vm._v(_vm._s(item.status))]),_c('span',{staticClass:"action"},[_c('md-icon',{staticClass:"bg-victoria rounded-full p-1 text-white text-base mr-1",nativeOn:{"click":function($event){return _vm.onShowDetails({
                label: 'Branch logistic request',
                content: item
                })}}},[_vm._v("visibility")])],1)]}}])})],1)],2)],1),_c('Dialog',[_c(_vm.component,{tag:"component",attrs:{"title":_vm.dialogTitle,"content":_vm.dialogContent,"options":_vm.options}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }